import "./assets/style/style.css";
import { useParams } from 'react-router-dom';
import {pagePayAPI} from "./API/api";

const ThirdPage = () =>{
  const { id } = useParams();
  //request
  const params = {
    "id": id,
    "paymentMode": "PAGE"
  };
  pagePayAPI(params);
};

export default ThirdPage;