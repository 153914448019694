import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import ThirdPage from './ThirdPage';

const App = () => {
  return (
    <Router basename={process.env.NODE_ENV === "development" ? '' : '/safepay/'}>
      <Routes>
        {/* Add the route for the root path */}
        <Route path="/thirdpay/:id" element={<ThirdPage />} />
        <Route path="/:id/:amount" element={<Home />} />
      </Routes>
    </Router>
  );
};
export default App;

